import React from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import { Button, FieldTextInput, Form, NamedLink } from '../../../components';

import css from './SearchKeywordForm.module.css';
import IconSearchDesktop from './IconSearchDesktop';
import { isLocaleGreek } from '../../../util/genericHelpers';

const SearchKeywordFormComponent = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          values,
          intl,
          isSearchPage,
          handleSubmit,
          viewport,
        } = formRenderProps;

        const searchInputPlaceholder = intl.formatMessage({
          id: 'SearchKeywordForm.searchInputPlaceholder',
        });

        const classes = classNames(
          rootClassName || css.root,
          className,
          css.searchPageFilter,
          isSearchPage ? css.searchPage : null
        );

        const isMobileLayout = viewport.width > 0 && viewport.width < 768;
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FieldTextInput
              className={css.field}
              type="text"
              id="keywords"
              name="keywords"
              placeholder={searchInputPlaceholder}
            />
            <Button
              className={classNames(css.searchIcon)}
              onClick={() => props.onSubmit(values)}
              type="submit"
            >
              <IconSearchDesktop /> {isMobileLayout ? '' : searchInputPlaceholder}
            </Button>
          </Form>
        );
      }}
    />
  );
};

SearchKeywordFormComponent.defaultProps = { rootClassName: null, className: null };

SearchKeywordFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SearchKeywordForm = injectIntl(SearchKeywordFormComponent);

export default SearchKeywordForm;
