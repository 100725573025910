import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { parse } from '../../../util/urlHelpers';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { NamedLink, ReactSelect } from '../../../components';
import {
  isArrayLength,
  isLocaleGreek,
  sortArrayAlphabetically,
  sortGreekCountriesLabels,
  sortGreekLabels,
} from '../../../util/genericHelpers';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { GROUPED_EXPERTISE, citiesOptions, genderOptions } from '../../../config/customConfig';

import css from './CustomFilter.module.css';
import getCountryCodes from '../../../translations/countryCodes';
import { useConfiguration } from '../../../context/configurationContext';

// Helper function to map options
export const mapOptions = (options, labelKey = 'label') => {
  if (!Array.isArray(options) || !options.length) return [];

  return options.map(option => ({
    value: option?.value,
    label: option?.[labelKey],
  }));
};

function CustomFilters(props) {
  const { countryCityFilter } = props;
  const countries = countryCityFilter?.countries;
  const cities = countryCityFilter?.cities;

  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const config = useConfiguration();
  const isGreek = isLocaleGreek();

  const [filterState, setFilterState] = useState({
    city: null,
    country: null,
    gender: null,
  });

  const parsedSearch = location?.search ? parse(location?.search) : null;

  const [filterOptions, setFilterOptions] = useState(
    parsedSearch
      ? { ...parsedSearch }
      : {
          pub_educationOrExperienceInUSA: false,
          pub_educationOrExperienceInUK: false,
          pub_educationOrExperienceInGermany: false,
          pub_country: false,
          pub_city: false,
          male: false, // Add "male" checkbox
          female: false, // Add "female" checkbox
        }
  );

  // Get speciality
  const speciality = parsedSearch?.pub_speciality;

  // Find grouped expertise
  const expertiseGrouped = GROUPED_EXPERTISE?.find(e => e?.key === speciality) || {};

  // Extract expertise options
  const expertiseOptions = expertiseGrouped.options || [];

  // Create the options for both languages
  const englishExpertiseOptions = mapOptions(expertiseOptions);
  const greekExpertiseOptions = mapOptions(expertiseOptions, 'greekLabel');

  // Get all options and sort them
  const allOptions = sortArrayAlphabetically(GROUPED_EXPERTISE?.flatMap(ge => ge?.options) || []);
  const englishAllOptions = mapOptions(allOptions);
  const greekAllOptions = mapOptions(allOptions, 'greekLabel');

  // Determine which options to use
  const useExpertiseOptions = speciality
    ? isGreek
      ? greekExpertiseOptions
      : englishExpertiseOptions
    : isGreek
    ? greekAllOptions
    : englishAllOptions;

  const uniqueExpertiseOptions =
    isArrayLength(useExpertiseOptions) &&
    useExpertiseOptions?.filter(
      (v, i, a) =>
        a?.findIndex(v2 => v2?.value === v?.value) === i &&
        a?.findIndex(v2 => v2?.label === v?.label) === i
    );

  useEffect(() => {
    if (
      filterOptions?.pub_educationOrExperienceInUK ||
      filterOptions?.pub_educationOrExperienceInUSA ||
      filterOptions?.pub_educationOrExperienceInGermany ||
      filterOptions?.pub_country ||
      filterOptions?.pub_city ||
      filterOptions?.pub_male || // Check for "male" checkbox
      filterOptions?.female // Check for "female" checkbox
    ) {
      handleFilterSubmit();
    }
  }, [filterOptions]);

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;
    setFilterOptions(prevState => ({
      ...prevState,
      [name]: checked,
    }));

    let filterParams = [];

    if (name === 'pub_educationOrExperienceInUSA') {
      if (checked) {
        filterParams.push('pub_educationOrExperienceInUSA=true');
      } else {
        filterParams = filterParams.filter(f => f.name !== 'pub_educationOrExperienceInUSA');
      }
    } else if (name === 'pub_educationOrExperienceInUK') {
      if (checked) {
        filterParams.push('pub_educationOrExperienceInUK=true');
      } else {
        filterParams = filterParams.filter(f => f.name !== 'pub_educationOrExperience');
      }
    } else if (name === 'pub_educationOrExperienceInGermany') {
      if (checked) {
        filterParams.push('pub_educationOrExperienceInGermany=true');
      } else {
        filterParams = filterParams.filter(f => f.name !== 'pub_educationOrExperience');
      }
    } else if (name === 'male') {
      // Handle "male" checkbox
      if (checked) {
        filterParams.push('pub_gender=male');
      } else {
        filterParams = filterParams.filter(f => !['male'].includes(f.name));
      }
    } else if (name === 'female') {
      // Handle "female" checkbox
      if (checked) {
        filterParams.push('pub_gender=female');
      } else {
        filterParams = filterParams.filter(f => !['female'].includes(f.name));
      }
    }

    const filterQuery = filterParams.join('&');
    history.push(`/search?${filterQuery}`);
  };

  const handleFilterSubmit = () => {
    const {
      pub_educationOrExperienceInUSA,
      pub_educationOrExperienceInUK,
      pub_educationOrExperienceInGermany,
      male,
      female,
    } = filterOptions;
    const filterParams = [];

    if (pub_educationOrExperienceInUSA) {
      filterParams.push('pub_educationOrExperienceInUSA=true');
    }
    if (pub_educationOrExperienceInUK) {
      filterParams.push('pub_educationOrExperienceInUK=true');
    }
    if (pub_educationOrExperienceInGermany) {
      filterParams.push('pub_educationOrExperienceInGermany=true');
    }
    if (male) {
      filterParams.push('pub_gender=male');
    }
    if (female) {
      filterParams.push('pub_gender=female');
    }

    const filterQuery = filterParams.join('&');
    history.push(`/search?${filterQuery}`);
  };

  const handleCustomFilter = (event, key) => {
    const { value } = event.target;
    if (value?.length > 1) {
      const searchParams = { ...parsedSearch, [key]: value };
      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
      );
    } else {
      history.push(`/search`);
    }
  };
  const countryCodes = getCountryCodes(config.localization.locale);
  const sortedCountryCode = isLocaleGreek() ? sortGreekCountriesLabels(countryCodes) : countryCodes;

  return (
    <div className={css.customFilters}>
      <div className={css.filterBlock}>
        <h2>{intl.formatMessage({ id: `CustomFilter.cityLabel` })}</h2>
        <select
          name="cities"
          value={filterState?.city || ''}
          onChange={e => {
            setFilterState({
              ...filterState,
              city: e.target.value,
            });
            handleCustomFilter(e, 'pub_city');
          }}
        >
          <option value="">{intl.formatMessage({ id: `CustomFilter.citySelect` })}</option>
          {isArrayLength(citiesOptions()) &&
            citiesOptions()?.map((c, i) => (
              <option value={c} key={i}>
                {c}
              </option>
            ))}
        </select>
        {parsedSearch?.pub_city ? (
          <p
            className={css.clear}
            onClick={() => {
              setFilterState({
                ...filterState,
                city: null,
              });
              history.push(`/search`);
            }}
          >
            {intl.formatMessage({ id: `CustomFilter.clear` })}
          </p>
        ) : null}
      </div>
      <div className={css.filterBlock}>
        <h2>{intl.formatMessage({ id: `CustomFilter.countryLabel` })}</h2>
        <select
          name="country"
          value={filterState?.country || ''}
          onChange={e => {
            handleCustomFilter(e, 'pub_country');
            setFilterState({
              ...filterState,
              country: e.target.value,
            });
          }}
        >
          <option value="">{intl.formatMessage({ id: `CustomFilter.countrySelect` })}</option>
          {isArrayLength(sortedCountryCode) &&
            sortedCountryCode?.map((c, i) => (
              <option value={c.name} key={i}>
                {c.name}
              </option>
            ))}
        </select>
        {parsedSearch?.pub_country ? (
          <p
            className={css.clear}
            onClick={() => {
              setFilterState({
                ...filterState,
                country: null,
              });
              history.push(`/search`);
            }}
          >
            {intl.formatMessage({ id: `CustomFilter.clear` })}
          </p>
        ) : null}
      </div>
      {isArrayLength(uniqueExpertiseOptions) ? (
        <div className={css.filterBlock}>
          <h2>{intl.formatMessage({ id: `CustomFilter.expertise` })}</h2>
          <ReactSelect
            options={sortGreekLabels(uniqueExpertiseOptions)}
            isMulti={true}
            handleChange={v => {
              const optionToSearch = isArrayLength(v) && v?.join(',');
              const searchParams = { ...parsedSearch, pub_expertise: `has_any:${optionToSearch}` };
              history.push(
                createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
              );
            }}
            locale={isGreek ? 'de' : 'en'}
          />
        </div>
      ) : null}
      <div className={css.filterBlock}>
        <h2>{intl.formatMessage({ id: `CustomFilter.genderTitle` })}</h2>
        <select
          name="gender"
          value={filterState?.gender || ''}
          onChange={e => {
            setFilterState({
              ...filterState,
              gender: e.target.value,
            });
            handleCustomFilter(e, 'pub_gender');
          }}
        >
          {isArrayLength(genderOptions) &&
            genderOptions?.map((c, i) => (
              <option value={c?.key} key={i} disabled={c?.disabled}>
                {isGreek ? c?.greekLabel : c?.label}
              </option>
            ))}
        </select>
        {parsedSearch?.pub_gender ? (
          <p
            className={css.clear}
            onClick={() => {
              setFilterState({
                ...filterState,
                gender: null,
              });
              history.push(`/search`);
            }}
          >
            {intl.formatMessage({ id: `CustomFilter.clear` })}
          </p>
        ) : null}
      </div>
      <div className={css.filterBlock}>
        <h2>
          {' '}
          <FormattedMessage id="DoctorOnboardingForm.educationTitle" />
        </h2>
        <div className={css.options}>
          <input
            type="checkbox"
            name="pub_educationOrExperienceInUSA"
            checked={filterOptions.pub_educationOrExperienceInUSA}
            onChange={handleCheckboxChange}
          />
          <FormattedMessage id="DoctorOnboardingForm.educationOrExperienceInUSAMessage" />
        </div>

        <div className={css.options}>
          <input
            type="checkbox"
            name="pub_educationOrExperienceInUK"
            checked={filterOptions.pub_educationOrExperienceInUK}
            onChange={handleCheckboxChange}
          />
          <FormattedMessage id="DoctorOnboardingForm.educationOrExperienceInUKMessage" />
        </div>
        <div className={css.options}>
          <input
            type="checkbox"
            name="pub_educationOrExperienceInGermany"
            checked={filterOptions.pub_educationOrExperienceInGermany}
            onChange={handleCheckboxChange}
          />
          <FormattedMessage id="DoctorOnboardingForm.educationOrExperienceInGermanyMessage" />
        </div>
        <div className={css.clearButton}>
          <NamedLink className={css.clearSearch} name="SearchPage">
            {intl.formatMessage({
              id: 'SearchKeywordForm.clearPlaceholder',
            })}
          </NamedLink>
        </div>
      </div>
    </div>
  );
}

export default CustomFilters;
